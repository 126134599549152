<template>
  <v-main class="pt-0 pl-2 pr-2" light>
    <v-sheet elevation="0" outlined light>
      <!-- Toolbar -->
      <v-row>
        <!-- Menu Vertical  -->
        <v-col lg="3" xl="2" md="4" sx="12" sm="12">
          <ListMenuInvoice/>
        </v-col>
        <!-- Content of tab -->
        <v-col lg="9" xl="10" md="8" sx="12" sm="12">
          <div class="d-block mt-3 pa-2">
            <router-view />
            <!-- <template v-if="itemMenu.component">
              <component :is="itemMenu.component"></component>
            </template> -->
          </div> 
        </v-col>
      </v-row>
    </v-sheet>
  </v-main>
</template>

<script>

import ListMenuInvoice from './components/InvoiceMenu'
export default {
  name: "InvoiceBillingComponent",
  components: {
    ListMenuInvoice,
  },
}
</script>

<style>

</style>