<template>
   <v-list
      nav
      dense
    >
      <v-subheader inset class="text-subtitle-1">Menu</v-subheader>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(itemTab, index) in listTabMenu"
          :key="index"
          :to="itemTab.path"
        >
          <v-list-item-icon>
            <v-icon>{{ itemTab.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="itemTab.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
  name: "InvoiceMenuSelectionComponent",
  watch: {
    selectItem() {
      this.findItemAndEmit();
    }
  },
  mounted() {
    this.findItemAndEmit();
  },
  data: () => {
    return  {
     
      listTabMenu: [
        { 
          icon: "mdi-information-variant",
          text: "Business Information",          
          path: '/invoice/business-info',
          namePath: 'BusinessInfo'
        },
        {
          icon: "mdi-text-box-plus-outline",
          text: "Create a new Invoice",
          path: "/invoice/create-new-invoice",
          namePath: "CreateNewInvoice"
        },
        {
          icon: "mdi-file-document-multiple-outline",
          text: "Create a general invoice",
          path: "/invoice/create-batch-invoice",
          namePath: "CreatePatchInvoice"
        },
        {
          icon: "mdi-format-list-bulleted-square",
          text: "List Invoices By Form & Serial",
          path: "/invoice/get-list-invoice-by-form-serial",
          namePath: "GetListInvoiceByFormAndSerial"
        },
        {
          icon: "mdi-file-document",
          text: "View detail invoice",
          path: '/invoice/view-invoice-detail-by-invoice-guid',
          namePath: "ViewDetailInvoiceByInvoiceGUID"
        }
      ]
    }
  },
  methods: {
    findItemAndEmit() {
      this.$emit('input', this.listTabMenu[this.selectItem]);
    }
  }
}
</script>

<style>

</style>